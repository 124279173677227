<template>
  <div class="tree-filter">
    <div
      v-if="orders.length"
      class="tree-filter__select"
    >
      <icon
        data="@tree/order_select.svg"
        class="size-16"
      />
      <v-select
        v-model="treeOrderModel"
        :items="orders"
        item-text="name"
        item-value="order"
        class="sl-select sl-select--white sl-select--200px tree-select"
        solo
        dense
      />
    </div>
    <div
      v-if="categoriesList.length"
      class="tree-filter__select"
    >
      <icon
        data="@tree/order_select.svg"
        class="size-16"
      />
      <v-select
        v-model="treeCategoriesModel"
        ref="categoriesSelect"
        :items="categoriesList"
        class="sl-multiple-select sl-select--white sl-multiple-select--200px tree-categories-select tree-select"
        :menu-props="{
          contentClass: 'sl-multiple-select__menu'
        }"
        item-text="value"
        :label="$t('Main.ShowCats')"
        return-object
        multiple
        solo
        dense
        @focus="handleCategoriesFocus"
        @blur="handleCategoriesBlur"
      />
    </div>
    <v-checkbox
      v-if="hasAbc"
      v-model="abcAnalysisModel"
      :label="$t('Main.Ui.chkAbc')"
      class="sl-checkbox tree-filter__checkbox"
    />
    <v-checkbox
      v-if="isStockoutOverstockVisible"
      v-model="hideStockModel"
      class="sl-checkbox tree-filter__checkbox"
    >
      <template #label>
        <div class="tree-filter__checkbox-label">
          <span>
            {{ $t('Main.DistortionsChk.Hide') }}
          </span>
          <div
            :class="{
              'tree-filter__checkbox--overstock': !hideStockModel
            }"
          >
            {{ $t('Main.DistortionsChk.overstock') }}
          </div> /
          <div
            :class="{
              'tree-filter__checkbox--stockout': !hideStockModel
            }"
          >
            {{ $t('Main.DistortionsChk.stockout') }}
          </div>
        </div>
      </template>
    </v-checkbox>
    <v-checkbox
      v-model="hideInactiveModel"
      :label="$t('Main.Ui.chkHideInactive')"
      class="sl-checkbox tree-filter__checkbox"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { access } from '@/mixins/access';
import {
  noChannelTreeOrders,
  noLocationTreeOrders,
  treeFilterKeys,
  treeOrders
} from '@/config/demand/tree/tree.config';
import cloneObject from '@/helpers/utils/cloneObject';
import { checkEqualObject } from '@/helpers/utils/checkEqualObject';
import { $amp_logEvent } from '@/plugins/product/amplitude';

const $amp_treeActionByFilterKey = {
  [treeFilterKeys.ORDER]: 'Tree Order Changed',
  [treeFilterKeys.CATEGORIES]: 'Categories in Tree Changed',
  [treeFilterKeys.ABC_ANALYSIS]: 'ABC Visibility in Tree Changed',
  [treeFilterKeys.HIDE_STOCK]: 'Distortions Visibility Changed',
  [treeFilterKeys.HIDE_INACTIVE]: 'Inactive Items Visibility Changed'
};

export default {
  name: 'TreeFilter',
  mixins: [access],
  data() {
    return {
      oldCategoriesState: null
    };
  },
  computed: {
    ...mapState({
      treeMetadata: (state) => state.demand.tree.metadata,
      categoriesList: (state) => state.demand.tree.categories_list
    }),
    ...mapGetters({
      hasAbc: 'project/hasAbc',
      hasLocation: 'project/hasLocation',
      hasChannel: 'project/hasChannel'
    }),
    orders() {
      if (this.hasChannel && this.hasLocation) {
        return treeOrders(this);
      }

      if (this.hasChannel) {
        return noLocationTreeOrders(this);
      }

      if (this.hasLocation) {
        return noChannelTreeOrders(this);
      }

      return [];
    },
    isStockoutOverstockVisible() {
      return this.$sl_hasAccess(this.$sl_features.salesData)
        && this.$sl_hasAccess(this.$sl_features.purchasingData);
    },
    treeOrderModel: {
      get() {
        return this.treeMetadata.order;
      },
      set(value) {
        this.handleFilterChange({
          [treeFilterKeys.ORDER]: value
        });
      }
    },
    treeCategoriesModel: {
      get() {
        return this.treeMetadata.categories;
      },
      set(value) {
        this.handleFilterChange({
          [treeFilterKeys.CATEGORIES]: value,
          needUpdate: false
        });
      }
    },
    abcAnalysisModel: {
      get() {
        return +this.treeMetadata.wantAbc;
      },
      set(value) {
        this.handleFilterChange({
          [treeFilterKeys.ABC_ANALYSIS]: value
        });
      }
    },
    hideStockModel: {
      get() {
        return +this.treeMetadata.wantHideDistortions;
      },
      set(value) {
        this.handleFilterChange({
          [treeFilterKeys.HIDE_STOCK]: value
        });
      }
    },
    hideInactiveModel: {
      get() {
        return +this.treeMetadata.wantHideInactive;
      },
      set(value) {
        this.handleFilterChange({
          [treeFilterKeys.HIDE_INACTIVE]: value
        });
      }
    }
  },
  methods: {
    ...mapActions('demand/tree', [
      'setFilterByKey',
      'updateTree'
    ]),
    handleFilterChange({ needUpdate = true, ...data }) {
      this.setFilterByKey({
        ...data,
        needUpdate
      });

      if (!needUpdate) {
        return;
      }

      const filterKey = Object.keys(data)[0];
      const filterValue = data[filterKey];
      const $amp_eventName = $amp_treeActionByFilterKey[filterKey];
      const $amp_eventPayload = filterKey === treeFilterKeys.CATEGORIES
        ? {}
        : filterKey === treeFilterKeys.ORDER
          ? { 'Demand Tree Order': filterValue }
          : { 'Checkbox Status': !!filterValue };

      $amp_logEvent($amp_eventName, $amp_eventPayload);
    },
    handleCategoriesFocus() {
      this.oldCategoriesState = cloneObject(this.treeMetadata[treeFilterKeys.CATEGORIES]);
    },
    handleCategoriesBlur() {
      if (!checkEqualObject(this.oldCategoriesState, this.treeCategoriesModel)) {
        this.handleFilterChange({
          [treeFilterKeys.CATEGORIES]: this.treeCategoriesModel
        });
      }

      if (this.$refs.categoriesSelect.isMenuActive) {
        this.$refs.categoriesSelect.blur();

        this.oldCategoriesState = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/tree/tree-filter.scss";
</style>
