<template>
  <SlContextMenu
    ref="contextMenu"
    element-id="sl-tree-context-menu"
    :options="treeContextMenuParsedOptions"
    data-shortcut-tree
    @option-clicked="handleOptionClick"
  >
    <template #[contextMenuKeys.COPY_ITEM_TO_CLIPBOARD]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.COPY_ITEM_CODE, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.COPY_ITEM_CODE, fgsActionTypes.ENABLED)"
      >
        {{ name }}
        <template #shortcut>
          <SlShortcut
            v-for="value in shortcutsConfig.COPY_CODE.shortcut"
            :key="value"
            :value="value"
          />
        </template>
      </OptionItem>
    </template>
    <template #[contextMenuKeys.APPROVE]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.APPROVE, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.APPROVE, fgsActionTypes.ENABLED)"
      >
        {{ name }}
        <template #shortcut>
          <SlShortcut
            v-for="value in shortcutsConfig.SET_APPROVE_UNAPPROVE.shortcut"
            :key="value"
            :value="value"
          />
        </template>
      </OptionItem>
    </template>
    <template #[contextMenuKeys.NEEDS_ATTENTION]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.NEEDS_ATTENTION, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.NEEDS_ATTENTION, fgsActionTypes.ENABLED)"
      >
        {{ name }}
        <template #shortcut>
          <SlShortcut
            v-for="value in shortcutsConfig.SET_ATTENTION.shortcut"
            :key="value"
            :value="value"
          />
        </template>
      </OptionItem>
    </template>
    <template #[contextMenuKeys.CHECKED]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.CHECK, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.CHECK, fgsActionTypes.ENABLED)"
      >
        {{ name }}
        <template #shortcut>
          <SlShortcut
            v-for="value in shortcutsConfig.SET_CHECKED.shortcut"
            :key="value"
            :value="value"
          />
        </template>
      </OptionItem>
    </template>
    <template #[contextMenuKeys.FIND_IN_INVENTORY]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.FIND_IN_IR, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.FIND_IN_IR, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.FIND_IN_DC]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.FIND_IN_DC, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.FIND_IN_DC, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.FIND_IN_REPORTS]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.FIND_IN_FR, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.FIND_IN_FR, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.ITEM_BOM]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.ITEM_BOM, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.ITEM_BOM, fgsActionTypes.ENABLED)"
      >
        {{ name }}
        <template #shortcut>
          <SlShortcut
            v-for="value in shortcutsConfig.OPEN_MATERIAL_PRODUCT_TREE.shortcut"
            :key="value"
            :value="value"
          />
        </template>
      </OptionItem>
    </template>
    <template #[contextMenuKeys.REMOVE_FROM_IMPORT]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.REMOVE_FROM_IMPORT, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.REMOVE_FROM_IMPORT, fgsActionTypes.ENABLED)"
      >
        {{ name }}
        <template #shortcut>
          <SlShortcut
            v-for="value in shortcutsConfig.REMOVE_FROM_IMPORT.shortcut"
            :key="value"
            :value="value"
          />
        </template>
      </OptionItem>
    </template>
    <template #[contextMenuKeys.SUBSTITUTE]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.SUBSTITUTE, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="isSubstituteDisabled"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.ADD_NOTE]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.ADD_NOTE, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.ADD_NOTE, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.EDIT_NOTE]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.EDIT_NOTE, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.EDIT_NOTE, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.CLEAR_OVERRIDES]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.CLEAR_OVERRIDES, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.CLEAR_OVERRIDES, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.CLEAR_SETTINGS]="{ handler, name, subList }">
      <OptionItem :handler="handler">
        <v-menu
          content-class="tree-context-menu__sublist"
          :open-delay="200"
          :close-delay="200"
          open-on-hover
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ name }}
                </v-list-item-title>
                <div class="icon-wrapper">
                  <icon
                    data="@icon/arrow-down.svg"
                    class="size-16 rotate--90"
                  />
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item
              v-for="subItem in subList"
              :key="subItem.key"
              class="tree-context-menu__sublist-item"
              :disabled="!getActionFgs(contextMenuFgs[subItem.fgs], fgsActionTypes.ENABLED)"
              @click="handleClearSettings(subItem.key)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ subItem.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </OptionItem>
    </template>
    <template #[contextMenuKeys.EXPORT_NODE_TO_XLSX]="{ handler, name, subList }">
      <OptionItem
        v-if="isExportToXlsxVisible"
        :handler="handler"
        :disabled="isExportToXlsxDisabled"
      >
        <v-menu
          content-class="tree-context-menu__sublist"
          :open-delay="200"
          :close-delay="200"
          open-on-hover
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ name }}
                </v-list-item-title>
                <div class="icon-wrapper">
                  <icon
                    data="@icon/arrow-down.svg"
                    class="size-16 rotate--90"
                  />
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item
              v-for="subItem in subList"
              :key="subItem.key"
              :disabled="!getActionFgs(contextMenuFgs[subItem.key], fgsActionTypes.ENABLED)"
              class="tree-context-menu__sublist-item"
              @click="handleExportNodeToXlsx(subItem.visitLeafs)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ subItem.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </OptionItem>
    </template>
  </SlContextMenu>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SlContextMenu from '@/components/Shared/SlContextMenu/Index.vue';
import OptionItem from '@/components/Shared/SlContextMenu/OptionItem.vue';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { shortcut } from '@/mixins/shortcut';
import { fileSaver } from '@/mixins/webAPI';
import {
  contextMenuKeys,
  fgsActionTypes,
  treeContextMenuActionsMap,
  treeContextMenuOptions,
  clearSettingsConfig
} from '@/config/shared/contextMenu.config';
import { contextMenuFgs } from '@/config/shared/fgs.config';
import { bomTypes } from '@/config/item/bom.config';
import modalsId from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';
import { namespaceByRoute } from '@/config/report';
import { shortcutsConfig } from '@/config/shortcuts/shortcuts.config';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { getFindInQuery } from '@/helpers/report/inventoryReport';
import { openLink } from '@/helpers/shared/webAPI';
import { copyToClipboard } from '@/helpers/utils/clipboard';
import { $amp_logEvent } from '@/plugins/product/amplitude';

export default {
  name: 'TreeContextMenu',
  components: {
    SlContextMenu,
    OptionItem
  },
  mixins: [modal, access, shortcut, fileSaver],
  data() {
    return {
      contextMenuKeys,
      contextMenuFgs,
      fgsActionTypes,
      getActionFgs: this.getActionFgs
    };
  },
  inject: [
    'getActionFgs'
  ],
  computed: {
    ...mapState({
      projectId: (state) => state.manageProjects.projectId,
      activeNode: state => state.demand.tree.active_node
    }),
    ...mapGetters({
      activeNodeId: 'demand/tree/activeNodeId',
      activeItemName: 'demand/tree/itemName',
      editableForSomeChildren: 'demand/tree/editableForSomeChildren',
      activeNodeThings: 'demand/tree/activeNodeThings'
    }),
    shortcutsConfig() {
      return shortcutsConfig(this).DEMAND_TREE.values;
    },
    findInTabs() {
      return [
        this.$sl_routes.INVENTORY,
        this.$sl_routes.REPORTS
      ].some(tab => this.$sl_hasRouteAccess(tab));
    },
    treeContextMenuParsedOptions() {
      return treeContextMenuOptions(this).filter(option => {
        if (!this.findInTabs) {
          return option.block !== 'find-in';
        }

        return true;
      });
    },
    isSubstituteDisabled() {
      return !this.getActionFgs(contextMenuFgs.SUBSTITUTE, fgsActionTypes.ENABLED);
    },
    canEditNode() {
      return this.getActionFgs(contextMenuFgs.ADD_NOTE, fgsActionTypes.ENABLED)
        || this.getActionFgs(contextMenuFgs.EDIT_NOTE, fgsActionTypes.ENABLED);
    },
    isExportToXlsxVisible() {
      return this.getActionFgs(contextMenuFgs.EXPORT_CURRENT_NODE_TABLE, fgsActionTypes.VISIBLE)
        || this.getActionFgs(contextMenuFgs.EXPORT_CHILD_NODES_TABLE, fgsActionTypes.VISIBLE);
    },
    isExportToXlsxDisabled() {
      return !this.getActionFgs(contextMenuFgs.EXPORT_CURRENT_NODE_TABLE, fgsActionTypes.ENABLED)
        && !this.getActionFgs(contextMenuFgs.EXPORT_CHILD_NODES_TABLE, fgsActionTypes.ENABLED);
    },
    findInInventoryQuery() {
      const { type, chan, ...data } = this.activeNodeThings;

      return data;
    },
    findInReportsQuery() {
      const { type, ...data } = this.activeNodeThings;

      return data;
    },
    isBomDisabled() {
      return !this.getActionFgs(contextMenuFgs.ITEM_BOM, fgsActionTypes.ENABLED);
    }
  },
  methods: {
    ...mapActions({
      getItemClones: 'substitutions/getItemClones',
      clearNodeOverrides: 'demand/table/clearNodeOverrides',
      clearNodeSettings: 'demand/table/clearNodeSettings',
      toggleCheckedItemState: 'demand/tree/toggleCheckedItemState',
      toggleAttentionItemState: 'demand/tree/toggleAttentionItemState',
      exportNodeToXlsx: 'demand/tree/exportNodeToXlsx',
      approve: 'demand/tree/approve'
    }),
    setActiveFilterId(namespace, index) {
      this.$store.dispatch(`${namespace}/setActiveFilterId`, index);
    },
    handleOptionClick(event) {
      if (event?.option?.key) {
        const action = treeContextMenuActionsMap[event.option.key];

        if (this[action]) {
          this[action]();
        }
      }
    },
    setNeedsAttention() {
      this.toggleAttentionItemState();
    },
    setChecked() {
      this.toggleCheckedItemState();
    },
    confirmClearNodeOverrides() {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        icon: 'style_warning_double',
        title: this.$t('Web.Modals.ActionConfirm.TitleClearOverrides'),
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonClear'),
        confirmCallback: this.clearNodeOverrides
      });
    },
    async handleRemoveFromImport() {
      await this.getItemClones(this.activeItemName);

      this.showModal(modalsId.REMOVE_FROM_IMPORT, {
        activeItemCode: this.activeItemName
      });
    },
    copyItem() {
      copyToClipboard(this.activeItemName);
    },
    handleExportNodeToXlsx(visitLeafs = false) {
      this.saveFile(this.exportNodeToXlsx.bind(this, visitLeafs));
    },
    async handleSubstitute() {
      if (!this.isSubstituteDisabled) {
        await this.getItemClones(this.activeItemName);

        this.showModal(modalsId.SUBSTITUTE_AN_ITEM, {
          activeItemCode: this.activeItemName
        });
      }
    },
    handleEditNote() {
      this.showModal(modalsId.ADD_NOTE);
    },
    async handleFindInInventory() {
      await this.setActiveFilterId(namespaceByRoute[routeNames.INVENTORY], DEFAULT_FILTER_ID);

      this.$router.push({
        name: routeNames.INVENTORY,
        query: {
          search: getFindInQuery(this.findInInventoryQuery)
        },
        params: {
          link: true
        }
      });
    },
    async handleFindInReports() {
      await this.setActiveFilterId(namespaceByRoute[routeNames.REPORTS], DEFAULT_FILTER_ID);

      this.$router.push({
        name: routeNames.REPORTS,
        query: {
          search: getFindInQuery(this.findInReportsQuery)
        },
        params: {
          link: true
        }
      });
    },
    handleOpenBoms() {
      this.$router.push({
        name: routeNames.BILL_OF_MATERIALS,
        params: {
          type: bomTypes.ITEM
        }
      });
    },
    handleCopyNodeLink() {
      copyToClipboard(window.location.href);
    },
    handleOpenNodeInNewTab() {
      openLink(window.location.href, true);
    },
    handleClearSettings(key) {
      if (this.editableForSomeChildren) {
        return this.showModal(modalsId.SL_CONFIRM_MODAL, {
          title: this.$t('Web.Tree.TitlePartiallyModify'),
          text: this.$t('Web.Tree.TextPartiallyModify', { 1: this.activeNode.text }),
          icon: 'style_warning',
          confirmText: this.$t('Web.Tree.BtnProceed'),
          confirmCallback: () => this.clearNodeSettingsByKey(key)
        });
      }

      this.clearNodeSettingsByKey(key);
    },
    clearNodeSettingsByKey(key) {
      this.clearNodeSettings(key);

      const paramName = clearSettingsConfig(this).find(o => o.key === key)?.title;

      $amp_logEvent('Forecasting Parameters Overridden', {
        'Forecasting Parameter Name': paramName,
        'Project Id': this.projectId
      });
    }
  }
};
</script>
